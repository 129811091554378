import settlement from '../../services/Settlement'
import router from "../../router";

// initial state
const state = {
  items: [],
  dialogNew: false,
  settlementSelected: null,
  studentSettlements: [],
};

// getters
const getters = {
  getSettlements: state => state.items,
  getSettlementsAssignedToUser: state => state.items.filter(p => p.coordinator_username != null),
  dialogNew: state => state.dialogNew,
  getSettlementNameById: state => settlementId => state.items.find(p => p.id === settlementId) != undefined ? state.items.find(p => p.id === settlementId).name : '',
  getSettlementSelected: state => state.settlementSelected,
  getStudentSettlements: state => state.studentSettlements
};

// actions
const actions = {
  getSettlements ({ commit }) {
    settlement.getSettlements().then(response => {
      commit('setSettlements', response.data)
    })
  },
  getSettlementsByUserId ({ rootState, commit }) {
    settlement.getSettlementsByUserId(rootState.studentCourses.studentCourses.find(item => item.uid == rootState.studentCourses.chosenStudentCourseUser).uid).then(response => {
      commit('setSettlements', response.data)
    })
  },
  createSettlement({ commit, rootState }, formData) {
    return new Promise((resolve, reject) => {
      settlement.createSettlement(rootState.users.loggedInUser.id, formData).then(response => {
        if(response.status == 200) {
          if (response.data && response.data.message !== 'All reports saved!') {
            commit('addSettlement', response.data)
          }
          resolve(response.data)
        } else {
          reject(response.status)
        }
      })
    }).catch((error) => { console.log(error) });
  },
  addSettlementMapping({ commit }, params) {
    return new Promise((resolve, reject) => {
      settlement.addSettlementMapping(params.settlementId, params.mapping).then(response => {
        if(response.status == 200) {
          resolve(200)
        } else {
          reject(response.status)
        }
      })
    }).catch((error) => { console.log(error) });
  },
  getSettlementByUrl ({ commit }, url) {
    return new Promise((resolve, reject) => {
      settlement.getFileByUrl(url).then(response => {
        if(response.status == 200) {
          resolve(response.data);
        } else {
          reject()
        }
      })
    }).catch((error) => { console.log(error) });
  },
  editSettlement ({ commit }, editedSettlement) {
    settlement.editSettlement(editedSettlement).then(response => {
      commit('editSettlement', response.data)
    })
  },
  deleteSettlements ({ commit }, deletedSettlements) {
    settlement.deleteSettlements(deletedSettlements.map(function(item) { return item["id"] })).then(response => {
      if (response.status == 200) {
        commit('deleteSettlements', deletedSettlements)
      }
    })
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setSettlementSelected ({ commit }, value) {
    commit('setSettlementSelected', value)
  },
  getStudentSettlementsFromAPI ({ commit }) {
    settlement.getStudentSettlements(state.settlementSelected).then(response => {
      commit('setStudentSettlements', response.data)
    })
  },
  updateStudentSettlementById({commit}, value) {
    commit('updateStudentSettlementById', value)
  },
  updateSettlementById({commit}, value) {
    commit('updateSettlementById', value)
  },
  addSettlement({commit}, value) {
    commit('addSettlement', value)
  }
};

// mutations
const mutations = {
  setSettlements (state, settlements) {
    state.items = settlements
  },
  addSettlement (state, settlement) {
    state.items.push(settlement)
  },
  editSettlement (state, settlement) {
    console.log(settlement)
  },
  deleteSettlements (state, settlements) {
    settlements.map(function(key) {
      const index = state.items.indexOf(key);
      state.items.splice(index, 1);
    });
  },
  updateStudentSettlementById (state, studentSettlement) {
    state.studentSettlements.find(x => x.id == studentSettlement.id).amount = studentSettlement.amount;
  },
  updateSettlementById (state, settlement) {
    state.items = state.items.map(item => item.id === settlement.id ? settlement : item);
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setSettlementSelected (state, value) {
    state.settlementSelected = value
    router.push('/accountant-settlements/' + value)
  },
  setStudentSettlements (state, settlements) {
    state.studentSettlements = settlements
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
