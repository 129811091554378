<template>
  <v-data-table
      :headers="headers"
      :items="settlements"
      sort-by="name"
      show-select
      :single-select=singleSelect
      class="elevation-1 ma-4"
      v-model="selected"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Lista wszystkich wpłat</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px" @input="v => v || close()">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark rounded small class="mb-2 ml-4" v-on="on" @click="addNew">Dodaj nową wpłatę</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                        v-model="studentCourses[0].username"
                        :error-messages="amountErrors"
                        label="Nazwisko i imię"
                        required
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select v-model="editedItem.scid"
                              :items="studentCourses"
                              item-text="courseName"
                              item-value="id"
                              menu-props="auto"
                              placeholder="Kierunki studenta"
                              single-line
                              deletable-chips
                              :error-messages="courseNameErrors"
                              required
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                        v-model="editedItem.amount"
                        :error-messages="amountErrors"
                        label="Kwota"
                        required
                        suffix="PLN"
                        @blur="$v.editedItem.amount.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-menu
                        ref="menuDate"
                        v-model="menuDate"
                        :close-on-content-click="true"
                        :return-value.sync="editedItem.date"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="editedItem.date"
                            :error-messages="dateErrors"
                            label="Data dodania"
                            required
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.date" :first-day-of-week="1" no-title scrollable @change="$refs.menuDate.save(editedItem.date)">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-7">
              <v-btn class="overline" color="grey" text @click="close"><v-icon left small>arrow_back</v-icon> <span>porzuc dodawanie i wroc do listy wszystkich raportów</span></v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary" rounded @click="save">{{ formTitle }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNewSuccess" max-width="800px">
          <v-card>
            <v-card-title>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <span class="headline">Poprawnie dodałeś raport</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>
              <v-container class="text-center">
                <v-row>
                  <v-col sm="12">
                    <v-icon class="green--text" x-large>check_circle_outline</v-icon>
                  </v-col>
                  <v-col sm="12">
                    {{editedItem.amount }}
                  </v-col>
                  <v-col sm="12">
                    <v-btn color="primary" rounded @click="dialogNewSuccess = false">Powrót do listy wszystkich raportów</v-btn>
                  </v-col>
                  <v-col sm="12">
                    lub
                  </v-col>
                  <v-col sm="12">
                    <v-btn color="primary" outlined rounded @click="closeDialogNewSuccessAndAddAnotherUser">Chcę dodać kolejny raport</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      Brak wpłat
    </template>
    <template v-slot:item.amount="{ item }">
      {{ item.amount/100 }} PLN
    </template>
    <template v-slot:item.studentCourseBankAccount="{ item }">
      {{ item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn icon>
        <v-icon
            small
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import moment from "moment/moment";

export default {
  name: "Settlements",
  mixins: [validationMixin],
  validations: {
    editedItem: {
      amount: {required},
      date: {required},
      scid: {required},
    }
  },
  computed: {
    ...mapGetters({
      settlements: 'settlements/getSettlements',
      studentCourses: 'studentCourses/getChosenStudentCoursesData',

    }),
    amountErrors () {
      const errors = [];
      if (!this.$v.editedItem.amount.$dirty) return errors;
      !this.$v.editedItem.amount.required && errors.push('Podanie kwoty jest wymagane');
      return errors
    },
    dateErrors () {
      const errors = [];
      if (!this.$v.editedItem.date.$dirty) return errors;
      !this.$v.editedItem.date.required && errors.push('Podanie daty jest wymagane');
      return errors
    },
    courseNameErrors () {
      const errors = [];
      if (!this.$v.editedItem.scid.$dirty) return errors;
      !this.$v.editedItem.scid.required && errors.push('Podanie kierunku jest wymagane');
      return errors
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodaj nową wplatę' : 'Edytuj wpłatę'
    }
  },
  props: {
    uid: null
  },
  data: () => ({
    selected: [],
    menuDate: false,
    editedIndex: -1,
    dialog: false,
    headers: [
      { text: 'Kwota', value: 'amount' },
      { text: 'Data', value: 'date' },
      { text: 'Kierunek', value: 'courseName' },
      { text: 'Rachunek', value: 'studentCourseBankAccount' },
      { text: '', value: 'action', sortable: false },
    ],
    editedItem: {
      amount: 0,
      cid: null,
      date: new Date().toISOString().substr(0, 10),
    },
    defaultItem: {
      amount: 0,
      cid: null,
      date: new Date().toISOString().substr(0, 10),
    },
  }),
  methods: {
    ...mapActions({
      createStudentSettlement: 'studentSettlements/create',
      editStudentSettlement: 'studentSettlements/edit',
      setStudentSettlementSelected: 'studentSettlements/setSelected',
      updateSettlementById: 'settlements/updateSettlementById',
      addSettlement: 'settlements/addSettlement',
      getStudentCoursesByUserId: 'studentCourses/getStudentCoursesByUserId'
    }),
    editItem (item) {
      console.log(item);
      console.log(this.studentCourses);
      this.$store.dispatch(
          'studentCourses/getStudentCoursesByUserId', {'settlement': true}
      );
      this.editedIndex = this.settlements.indexOf(item);
      this.setStudentSettlementSelected(item.id);
      this.editedItem = Object.assign({}, {...item, amount: item.amount/100});
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.clearEditItem()
    },
    clearEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
    },
    addNew () {
      this.$store.dispatch(
          'studentCourses/getStudentCoursesByUserId', {'settlement': true}
      );
    },
    save () {
      this.$v.$touch()
      if (!this.$v.editedItem.amount.$invalid) {
        let editedItemWithMultipliedAmount = {
          ...this.editedItem,
          amount: this.editedItem.amount * 100,
          courseName: this.studentCourses.find(x => x.id === this.editedItem.scid).courseName,
          cid: this.studentCourses.find(x => x.id === this.editedItem.scid).cid,
          studentCourseBankAccount: this.studentCourses.find(x => x.id === this.editedItem.scid).studentCourseBankAccount
        };
        if (this.editedIndex > -1) {
          this.loader = 'loading'
          this.loading = true
          this.editStudentSettlement(editedItemWithMultipliedAmount).then(response => {
            if (response == 200) {
              this.updateSettlementById(editedItemWithMultipliedAmount);
              this.$log.info('Amount changed ', response)
              this.loading = false
              this.$v.$reset();
              this.clearEditItem();
              this.dialog = false;
            }
          }, error => {
            this.error = true
            this.loading = false
            this.$log.error('Rate per km change error ', error)
          })
        } else {
          this.createStudentSettlement(editedItemWithMultipliedAmount).then(response => {
            if (response == 200) {
              this.addSettlement(editedItemWithMultipliedAmount);
              this.$log.info('Amount added ', response)
              this.loading = false
              this.$v.$reset();
              this.clearEditItem();
              this.dialog = false;
            }
          }, error => {
            this.error = true
            this.loading = false
            this.$log.error('Rate per km change error ', error)
          })
        }
      }
    },
  },
  created() {
    this.$store.dispatch('settlements/getSettlementsByUserId');
  },
}
</script>

<style scoped>

</style>
