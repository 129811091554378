const step = 10000;
let start = 180000;
let end = 1000000;

const PriceChoicesEnum = Object.freeze([
  {key: start, value: `${start/100}zł`},
  ...new Array(Math.ceil((end - start) / step)).fill(0).map((_, i) => {
    let val = start + (i * step);
    return {key: val, value: `${val/100}zł`}
  })
]);

export default PriceChoicesEnum;
