import Api from './Api'

export default {
  createStudentCourse (studentData) {
    return Api().post('/api/student-course', studentData)
  },
  getStudentCoursesGrouped (parameters = null) {
    return Api().get('/api/student-course/grouped?' + (parameters.date ? '&date=' + parameters.date : '') + (parameters.pid ? '&pid=' + parameters.pid : '') + (parameters.cid ? '&cid=' + parameters.cid : '') + (parameters.settlement ? '&settlement=' + parameters.settlement : '') + (parameters.groupBy ? '&groupBy=' + parameters.groupBy : ''), { withCredentials: true })
  },
  getStudentCoursesTrends () {
    return Api().get('/api/student-course/trends', { withCredentials: true })
  },
  getStudentCoursesGroups (parameters = null) {
    return Api().get('/api/student-course/groups?' + (parameters.date ? '&date=' + parameters.date : '') + (parameters.pid ? '&pid=' + parameters.pid : '') + (parameters.cid ? '&cid=' + parameters.cid : ''), { withCredentials: true })
  },
  getStudentCourses (parameters = null) {
    return Api().get('/api/student-course?' + (parameters.date ? '&date=' + parameters.date : '') + (parameters.pid ? '&pid=' + parameters.pid : '') + (parameters.cid ? '&cid=' + parameters.cid : '') + (parameters.settlement ? '&settlement=' + parameters.settlement : ''), { withCredentials: true })
  },
  getStudentCoursesByUserId (userId, parameters = null) {
    return Api().get('/api/student-course/user/' + userId + '?' + (parameters.settlement ? '&settlement=' + parameters.settlement : ''), { withCredentials: true })
  },
  setStudentCourseFinish (chosenStudentCourse, studentData) {
    return Api().put('/api/student-course/' + chosenStudentCourse + '/finish', studentData)
  },
  editStudentCourse (studentData) {
    return Api().put('/api/student-course/' + studentData.id, studentData)
  },
  deleteStudentCourse (studentId) {
    return Api().delete('/api/student-course/' + studentId)
  },
  deleteStudentCourses (studentCourses) {
    return Api().post('/api/student-course/delete-multiple', studentCourses)
  },
  uploadAlbumNumbers (formData) {
    return Api().post('/api/student-course/upload-album-numbers', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  setAlbumNumbers (numbers) {
    return Api().post('/api/student-course/set-album-numbers', numbers)
  },
  setPrice (studentData) {
    return Api().put('/api/student-course/' + studentData.id + '/set-price/' + studentData.price)
  },
}
