import Api from './Api'

export default {
  createSettlement (userId, formData) {
    return Api().post('/api/student-settlements/user/' + userId, formData, {
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // }
    }
    )
  },
  edit (settlementData) {
    return Api().put('/api/student-settlements/' + settlementData.id, settlementData)
  }
}
