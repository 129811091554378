import studentSettlement from '../../services/StudentSettlement'

// initial state
const state = {
  dialogNew: false,
  selected: null
};

// getters
const getters = {
  dialogNew: state => state.dialogNew,
  getSelected: state => state.selected
};

// actions
const actions = {
  edit ({ commit }, editedStudentSettlement) {
    return new Promise((resolve, reject) => {
      studentSettlement.edit(editedStudentSettlement).then(response => {
        if(response.status == 200) {
          resolve(200);
        } else {
          reject()
        }
      })
    }).catch((error) => { console.log(error) });
  },
  create ({ commit, rootState }, newStudentSettlement) {
    return new Promise((resolve, reject) => {
      studentSettlement.createSettlement(rootState.studentCourses.chosenStudentCourseUser ,newStudentSettlement).then(response => {
        if(response.status == 200) {
          resolve(200);
        } else {
          reject()
        }
      })
    }).catch((error) => { console.log(error) });
  },
  setDialogNew({commit}, value) {
    commit('setDialogNew', value)
  },
  setSelected ({ commit }, value) {
    commit('setSelected', value)
  },
};

// mutations
const mutations = {
  edit (state, studentSettlement) {
    console.log(studentSettlement)
  },
  setDialogNew(state, value) {
    state.dialogNew = value
  },
  setSelected (state, value) {
    state.selected = value
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
