<template>
  <v-container>
    <v-card>
<!--      <v-card-title>Raport </v-card-title>-->
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="studentSettlements"
            :items-per-page="10"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar color="white" flat>
              <v-toolbar-title>Raport </v-toolbar-title>
<!--              <v-divider-->
<!--                  class="mx-4"-->
<!--                  inset-->
<!--                  vertical-->
<!--              ></v-divider>-->
              <v-spacer></v-spacer>
              <v-btn class="overline ml-5" color="grey" text @click="$router.push('/accountant-settlements')"><v-icon left small>arrow_back</v-icon> powrót do listy wszystkich raportów</v-btn>
              <v-dialog v-model="dialog" max-width="800px" @input="v => v || close()">
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-btn color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj nowy raport</v-btn>-->
<!--                </template>-->
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-text-field
                              v-model="editedItem.amount"
                              :error-messages="amountErrors"
                              label="Kwota"
                              required
                              suffix="PLN"
                              @blur="$v.editedItem.amount.$touch()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="pa-7">
                    <v-btn class="overline" color="grey" text @click="close"><v-icon left small>arrow_back</v-icon> <span>porzuc dodawanie i wroc do listy wszystkich raportów</span></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="primary" rounded @click="save">{{ formTitle }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogNewSuccess" max-width="800px">
                <v-card>
                  <v-card-title>
                    <v-container class="text-center">
                      <v-row>
                        <v-col sm="12">
                          <span class="headline">Poprawnie dodałeś raport</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text>
                    <v-divider></v-divider>
                    <v-container class="text-center">
                      <v-row>
                        <v-col sm="12">
                          <v-icon class="green--text" x-large>check_circle_outline</v-icon>
                        </v-col>
                        <v-col sm="12">
                          {{editedItem.amount }}
                        </v-col>
                        <v-col sm="12">
                          <v-btn color="primary" rounded @click="dialogNewSuccess = false">Powrót do listy wszystkich raportów</v-btn>
                        </v-col>
                        <v-col sm="12">
                          lub
                        </v-col>
                        <v-col sm="12">
                          <v-btn color="primary" outlined rounded @click="closeDialogNewSuccessAndAddAnotherUser">Chcę dodać kolejny raport</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount/100 }} PLN
          </template>
          <template v-slot:item.studentCourseBankAccount="{ item }">
            {{ item.studentCourseBankAccount ? item.studentCourseBankAccount : item.bank_account }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn icon>
              <v-icon
                  small
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {numeric, required} from "vuelidate/lib/validators";
import Message from "../components/Message.vue";
import {validationMixin} from "vuelidate";

export default {
  components: {Message},
  mixins: [validationMixin],

  validations: {
    editedItem: {
      amount: {required},
    }
  },
  name: "AccountantSettlement",
  computed: {
    ...mapGetters({
      studentSettlements: 'settlements/getStudentSettlements',
      getSettlementSelected: 'studentSettlements/getStudentSettlementSelected'
    }),
    amountErrors () {
      const errors = [];
      if (!this.$v.editedItem.amount.$dirty) return errors;
      !this.$v.editedItem.amount.required && errors.push('Podanie kwoty jest wymagane');
      return errors
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodaj nowy raport' : 'Edytuj raport'
    },
  },
  methods: {
    ...mapActions({
      editStudentSettlement: 'studentSettlements/edit',
      setStudentSettlementSelected: 'studentSettlements/setSelected',
      updateStudentSettlementById: 'settlements/updateStudentSettlementById'
    }),
    editItem (item) {
      this.editedIndex = this.studentSettlements.indexOf(item);
      this.setStudentSettlementSelected(item.id);
      this.editedItem = Object.assign({}, {...item, amount: item.amount/100});
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.clearEditItem()
    },
    clearEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1
    },
    save () {
      this.$v.$touch()
      if (!this.$v.editedItem.amount.$invalid) {
        this.loader = 'loading'
        this.loading = true
        let editedItemWithMultipliedAmount = {...this.editedItem, amount:this.editedItem.amount*100};
        this.editStudentSettlement(editedItemWithMultipliedAmount).then(response => {
          if(response == 200) {
            this.updateStudentSettlementById(editedItemWithMultipliedAmount);
            this.$log.info('Amount changed ', response)
            this.loading = false
            this.$v.$reset()
            this.dialog = false;
          }
        }, error => {
          this.error = true
          this.loading = false
          this.$log.error('Rate per km change error ', error)
        })
      }
    },
  },
  data: () => ({
    dialogNewSuccess: false,
    headers: [
      { text: 'Użytkownik', value: 'name' },
      { text: 'Kwota', value: 'amount' },
      { text: 'Data', value: 'date' },
      { text: 'Kierunek', value: 'courseName' },
      { text: 'Rachunek', value: 'studentCourseBankAccount' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      amount: 0
    },
    defaultItem: {
      amount: 0,
    },
  }),
  mounted() {
    this.$store.dispatch('settlements/getStudentSettlementsFromAPI');
  },
}
</script>

<style scoped>

</style>
